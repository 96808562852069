import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Table, Button, Modal, Input} from 'antd';
import {Context} from '../../AppContext';
import Customer from '../../Models/Customer';
import {Link} from 'gatsby';
import CustomerForm from '../../Forms/CustomerForm';

const labels = Customer.labels;
const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function CustomerPage(props) {
  const app = useContext(Context);
  const [records, setRecords] = useState(null);
  const [filters, _setFilters] = useState({
    ...PAGINATION_INIT,
  });
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: labels.name,
      render: (record) => (
        <Link to={`/customer/?id=${record.id}`}>{record.name}</Link>
      ),
    },
    {
      title: labels.company_title,
      dataIndex: 'company_title',
      key: 'company_title',
    },
    {title: labels.uni_number, dataIndex: 'uni_number', key: 'uni_number'},
    {title: labels.tel, dataIndex: 'tel', key: 'tel'},
    {title: labels.fax, dataIndex: 'fax', key: 'fax'},
    {title: labels.address, dataIndex: 'address', key: 'address'},
    // {title: labels.pay_date, dataIndex: 'pay_date', key: 'pay_date'},
    {title: labels.pay_period, dataIndex: 'pay_period', key: 'pay_period'},
  ];

  const getRecords = useCallback(
    async (search = '') => {
      app.actions.setLoading(true);
      try {
        const params = {
          query: {
            name: {$regex: search},
          },
          paging: {
            offset: (filters.current - 1) * filters.pageSize,
            limit: filters.pageSize,
          },
          sorting: ['-created'],
        };
        let resp = await app.actions.getCustomers(params);
        setRecords(resp.results);
        setTotal(resp.total);
      } catch (ex) {
        console.warn(ex);
      }
      app.actions.setLoading(false);
    },
    [app.actions, filters],
  );

  function setFilters(obj) {
    _setFilters((prev) => ({
      ...prev,
      ...PAGINATION_INIT,
      ...obj,
    }));
  }

  useEffect(() => {
    getRecords();

    return () => setRecords([]);
  }, [getRecords]);

  return (
    <Wrapper>
      <div className="action-bar">
        <Input
          value={search}
          placeholder="搜尋姓名"
          style={{width: 250}}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button type="primary" onClick={() => getRecords(search)}>
          刷新
        </Button>
        <div style={{flex: 1}} />
        {/* <Button type="primary">匯出客戶清單</Button> */}
        <Button
          type="primary"
          onClick={() => {
            app.actions.setModalWidth(900);
            app.actions.setModal(
              <CustomerForm
                record={new Customer()}
                onUpdate={() => getRecords(search)}
              />,
            );
          }}>
          ＋
        </Button>
      </div>

      {records && (
        <Table
          columns={columns}
          rowKey="id"
          dataSource={records}
          pagination={{
            pageSize: filters.pageSize,
            current: filters.current,
            total,
          }}
          onChange={(pagination) =>
            setFilters({
              pageSize: pagination.pageSize,
              current: pagination.current,
            })
          }
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 72px 40px;
  background-color: white;

  & > .action-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > button {
      margin-left: 10px;
    }
  }
`;
